import React from "react";
import Layout from "../../components/Layout";
import { Link } from "gatsby";
import Helmet from 'react-helmet'

const ThankYou = () => (
  <>
  <Helmet>
    <meta name="robots" content="noindex, nofollow" />
  </Helmet>
  <Layout>
  <nav className="breadcrumbs-nav section">
      <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<Link className="inline-link" to="/contact-us/">Contact us</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600'}}><b>Thank you!</b></span>
  </nav>
    <section className="section">
      <div className="container">
        <div className="content" style={{textAlign:'center'}}>
          <img
            src="/img/thank-you-msg.svg"
            alt="thank you!"
            style={{ width: "33%" }}
          />
          <h1>The message has been sent. Thank you !</h1>
          <p> Our team will shortly get in touch with you. </p>
          <a href="/"> Return to the website </a>
          <br></br>
          <br></br>
          <br></br>
          <div style={{ width: "80%", marginLeft: "10%" }}>
            <Link to="/offer/" className="btn">
              Offer
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link to="/portfolio/" className="btn">
              Portfolio
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link className="btn" to="#newsletter">
              Newsletter
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link className="btn" to="/ebook/">
              E-book
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div>
        </div>
      </div>
    </section>
  </Layout>
  </>
);

export default ThankYou;
